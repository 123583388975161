@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Source+Sans+Pro:wght@300;400&display=swap");

// font-family: 'Poppins', sans-serif;
// font-family: 'Source Sans Pro', sans-serif;

$black: #000;
$grey: #808080;
$white: #fff;
$primary: #0d0551;
$degraded_primary: #1e407e;
$secondary: #53dd6c;
$tertiary: #30e9ff;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
}

h1 {
	font-family: "Source Sans Pro", sans-serif;
	font-size: 24px;
	font-weight: 400;
	color: $white;
	text-align: center;
	strong {
		font-size: 36px;
	}
}

h2 {
	font-family: "Source Sans Pro", sans-serif;
	font-size: 36px;
	font-weight: 400;
	color: $white;
	text-align: center;
	margin-bottom: 30px;
}

h3 {
	font-family: "Source Sans Pro", sans-serif;
	font-size: 24px;
	font-weight: 400;
	color: $white;
	margin-bottom: 20px;
}

h4 {
	font-family: "Source Sans Pro", sans-serif;
	font-size: 20px;
	font-weight: 400;
	color: $white;
	text-decoration: underline;
}

p {
	font-family: "Poppins", sans-serif;
	color: $white;
	font-size: 16px !important;
	margin-bottom: 30px;
}

i {
	margin: 0 5px;
	padding: 10px;
	border-radius: 10px;
	background: $secondary;
	transition: 0.3s;
	box-shadow: 0px 0px 5px 0px $black;
	color: $primary;
}
i:hover {
	color: $white;
	box-shadow: none;
}

a,
input[type="submit"] {
	font-family: "Poppins", sans-serif;
	text-decoration: none;
	color: $white;
	font-size: 16px !important;
	transition: 0.3s;
}
a:hover,
input[type="submit"]:hover {
	color: $secondary;
}

.button {
	margin: 25px auto;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	a,
	input[type="submit"] {
		margin: 0 auto;
		font-family: "Poppins", sans-serif;
		border: solid 1px $white;
		border-radius: 10px;
		padding: 10px;
		text-align: center;
		transition: 0.3s;
		background: none;
	}
	a:hover,
	input[type="submit"]:hover {
		border: solid 1px $secondary;
		color: $secondary;
	}
}

.picto {
	width: 100%;
	color: $white;
	height: 50px !important;
	margin: 15px 0;
}

hr {
	width: 150px;
}

.loader {
	display: flex;
	flex-wrap: wrap;
	img {
		width: 150px;
		margin: 50px auto;
	}
}

.columns {
	margin: 25px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.column1_8 {
		width: 12.5%;
	}
	.column1_4 {
		width: 25%;
	}
	.column1_3 {
		width: 33%;
	}
	.column1_2 {
		width: 50%;
	}
	.column2_3 {
		width: 66%;
	}
	.column3_4 {
		width: 75%;
	}
}

.social_nav {
	width: 100%;
	margin: 50px 0;
	justify-content: space-around;
	div {
		text-align: center;
		a {
			border-radius: 50%;
			border: solid 2px $white;
			padding: 15px 12.5px 10px 12.5px;
			transition: 0.3s;
			svg {
				color: $white;
				height: 20px;
				width: 20px;
				transition: 0.3s;
			}
		}
		a.linkedin:hover {
			border: solid 2px #0a66c2;
			svg {
				color: #0a66c2;
			}
		}
		a.twitch:hover {
			border: solid 2px #9146ff;
			svg {
				color: #9146ff;
			}
		}
		a.instagram:hover {
			border: solid 2px #e4405f;
			svg {
				color: #e4405f;
			}
		}
		a.facebook:hover {
			border: solid 2px #1877f2;
			svg {
				color: #1877f2;
			}
		}
		a.discord:hover {
			border: solid 2px #5865f2;
			svg {
				color: #5865f2;
			}
		}
		a.github:hover {
			border: solid 2px #808080;
			svg {
				color: #808080;
			}
		}
		a.envelope:hover,
		a.phone:hover,
		a.webiste:hover {
			border: solid 2px $secondary;
			svg {
				color: $secondary;
			}
		}
	}
}

#root {
	background: $primary;
}

.is-sticky {
	transition: 0.5s;
	position: fixed;
	top: 0;
	left: 0;
	height: 110px;
	width: 100%;
	z-index: 5;
	box-shadow: 0 0 20px 0 rgba($black, 50%);
	background-color: $primary !important;
	border-bottom: solid 1px $secondary;
}

header {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.navbar {
		z-index: 3;
		align-items: center;
		position: absolute;
		max-width: 1170px;
		width: 100%;
		margin: auto;
		padding: 1rem;
		min-height: 75px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		color: $white;
		.logo {
			img {
				width: 200px;
			}
		}
		.navbar_links {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			width: 50%;
			justify-content: space-between;

			.navbar_link {
				padding: 0 0.3rem;
				color: inherit;
			}
			a {
				color: $white;
				transition: 0.3s;
			}
			a:hover {
				color: $secondary;
			}
		}
		.navbar_burger {
			display: none;
			width: 40px;
			height: 40px;
			background: transparent;
			border: none;
			color: inherit;
		}
	}
}

main {
	height: 100vh;
	.player_card {
		margin: 0 auto;
		padding-top: 20vh;
		width: 600px;
		display: flex;
		flex-wrap: wrap;
		.profil_picture {
			box-shadow: 0px 0px 25px 0px rgba($black, 50%);
			z-index: 1;
			margin: 0 auto;
			width: 300px;
			border-radius: 50%;
			border: solid 10px $secondary;
			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				filter: grayscale(80%);
				transition: 0.3s;
			}
		}
		.profil_picture:hover {
			img {
				filter: grayscale(0%);
			}
		}
		.player_info {
			box-shadow: 0px 0px 25px 0px rgba($black, 50%);
			z-index: 0;
			margin-top: -150px;
			padding-top: 175px !important;
			padding: 20px;
			width: 100%;
			border-radius: 16px;
			background: $primary;
		}
	}
}

#projects,
#legals {
	padding-top: 100px;
}

#error {
	padding-top: 100px;
	h1 {
		font-size: 200px;
	}
	h2 {
		text-align: left;
		font-size: 75px;
		margin-bottom: 0;
	}
	h3 {
		margin-bottom: 10px;
	}
	.error_number {
		margin: 0 auto;
	}
	.error_desc {
		padding: 50px 0;
	}
}
#skills,
#contact {
	background: $degraded_primary;
}

section {
	margin: 0 auto;
	max-width: 1170px;
	padding: 20px;
	.hobbies {
		.hobby {
			margin: 0 5px 20px 5px;
			display: flex;
			flex-wrap: wrap;
			padding: 20px 10px;
			border-radius: 10px;
			box-shadow: 0px 0px 5px 0px rgba($black, 50%);
			transition: 0.3s;
			border: solid 1px $secondary;
			background: none;
			svg {
				width: 100%;
				height: 20px;
				color: $secondary;
				transition: 0.3s;
			}
			strong {
				margin-top: 15px;
				width: 100%;
				text-align: center;
				color: $secondary;
				transition: 0.3s;
			}
		}
	}
	.technical_skills {
		justify-content: left;
		padding-right: 20px;
		img {
			border-radius: 10px;
			height: 35px;
			margin: 10px;
			box-shadow: 0px 0px 5px 0px rgba($black, 50%);
			transition: 0.3s;
		}
		img:hover {
			box-shadow: none;
		}
	}
	.personal_skills {
		margin-top: 30px;
		justify-content: left;
		.skill {
			margin: 0 10px 20px 10px;
			display: flex;
			flex-wrap: wrap;
			padding: 10px;
			border-radius: 10px;
			border: solid 1px $secondary;
			box-shadow: 0px 0px 5px 0px rgba($black, 50%);
			transition: 0.3s;
			background: none;
			padding: 10px;
			svg {
				width: 16.5px;
				height: 16.5px;
				color: $secondary;
				transition: 0.3s;
				margin-right: 10px;
			}
			strong {
				font-size: 14px;
				color: $secondary;
				transition: 0.3s;
			}
		}
	}

	.project {
		border: solid 1px $secondary;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0px 0px 15px 0px rgba($black, 50%);
		.project_img {
			margin: auto 0;
			img {
				width: 100%;
				border-radius: 10px;
				border: solid 2px $secondary;
			}

			.social_nav {
				justify-content: right;
				a {
					margin-left: 10px !important;
				}
			}
		}
		.project_desc {
			padding-left: 50px;
			.status {
				margin: 0 !important;
				.termine {
					background: rgba(green, 75%);
					padding: 5px 10px;
					border-radius: 10px;
				}
				.en_cours {
					background: rgba(orange, 75%);
					padding: 5px 10px;
					border-radius: 10px;
				}
				.en_preparation {
					background: rgba(red, 75%);
					padding: 5px 10px;
					border-radius: 10px;
				}
			}
			.technos {
				justify-content: left;
				img {
					margin: 10px;
					box-shadow: 0px 0px 5px 0px rgba($black, 50%);
					transition: 0.3s;
				}
				img:hover {
					box-shadow: none !important;
				}
			}
		}
	}
	form {
		input.field,
		textarea.field {
			padding: 20px;
			background: none;
			border: none;
			border-bottom: solid 1px $white;
			width: 100%;
			font-size: 20px;
			color: $white;
			font-family: "Poppins", sans-serif;
		}
		input.field::placeholder,
		textarea.field::placeholder {
			font-size: 18px;
			color: $white;
		}
		input.name {
			width: 90%;
			padding-bottom: 20px;
		}
		input.message {
			height: 100px;
			margin-bottom: 20px;
		}
		label.newsletter {
			margin: 0 20px;
			font-size: 18px;
			color: $white;
		}
		select {
			padding: 20px;
			background: none;
			border: none;
			border-bottom: solid 1px $white;
			width: 10%;
			font-size: 20px;
			color: $white;
			font-family: "Poppins", sans-serif;
		}
		.form_error {
			color: $secondary;
			margin-bottom: 0 !important;
		}
	}
}

footer {
	margin: 0 auto;
	max-width: 1170px;
	padding: 10px 20px;
	.columns {
		.logo {
			width: 100%;
			img {
				width: 100%;
			}
		}
	}
	.footer_nav {
		width: 100%;
		.footer_links {
			text-align: center;
			list-style: none;
		}
	}
}

@media screen and (max-width: 767px) {
	.is-sticky {
		display: block !important;
	}

	.columns {
		justify-content: space-around;
		.column1_8 {
			width: 25%;
		}
		.column1_4 {
			width: 50%;
		}
		.column1_3 {
			width: 100%;
		}
		.column2_3 {
			width: 100%;
		}
		.column1_2 {
			width: 100%;
		}
		.column3_4 {
			width: 100%;
		}
	}

	.social_nav {
		.column1_8 {
			margin: 25px 0;
		}
	}

	.navbar {
		background-color: $primary;
		position: fixed !important;
	}
	.navbar_links {
		flex-direction: column;
		justify-content: center !important;
		align-items: center;
		position: fixed;
		right: -100vw;
		bottom: 0;
		width: 100% !important;
		height: 100vh;
		padding: 2rem;
		visibility: hidden;
		background-color: $primary;
		.navbar_link {
			display: block;
			padding: 1.5rem !important;
			font-size: 5vw !important;
		}
	}

	.show-nav .navbar_links {
		width: 100vw;
		right: 0;
		visibility: visible;
	}

	.navbar_burger {
		margin-top: 20px;
		display: block !important;
		cursor: pointer;
		position: fixed;
		top: 1rem;
		right: 1rem;
	}

	.burger_bar,
	.burger_bar::before,
	.burger_bar::after {
		display: block;
		width: 40px;
		height: 3px;
		position: relative;
		border-radius: 3px;
		background: $white;
	}
	.burger_bar::before,
	.burger_bar::after {
		content: "";
		position: absolute;
		left: 0;
	}

	.burger_bar::before {
		transform: translateY(-12px);
		transition: 0.3s;
	}

	.burger_bar::after {
		transform: translateY(12px);
		transition: 0.3s;
	}

	.show-nav .burger_bar {
		background: none;
	}
	.show-nav .burger_bar::before {
		transform: rotate(45deg);
	}
	.show-nav .burger_bar::after {
		transform: rotate(-45deg);
	}

	main {
		.player_card {
			width: 90vw;
			.profil_picture {
				width: 40vw;
			}
			.player_info {
				margin-top: -75px;
				padding-top: 100px !important;
			}
		}
	}
	section {
		.project {
			.project_img {
				.social_nav {
					justify-content: center;
				}
			}
			.project_desc {
				padding-left: 10px;
			}
		}
	}
	form {
		display: flex;
		flex-wrap: wrap;
		input.name {
			width: 70% !important;
		}
		select {
			width: 30% !important;
		}
	}
}
